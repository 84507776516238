import React from "react";
import { Link } from "gatsby";
import FixedImage from "../fixedImage";
import ModalLink from "../modalLink";

import * as styleSheet from "./index.module.scss";

const WPLink = ({ link, passedClass, icon = null, passedActiveClass, passedIconClass = null }) => {
	if( link.url.indexOf('/modal/') !== -1 ){
		//Is a link to a modal
		return <ModalLink modal={link.url} link={link} passedClass={passedClass ? passedClass : styleSheet} icon={icon} />
	} else {
		const activeClass = !passedActiveClass ? passedClass : passedActiveClass;
		const rel = link.target === "_blank" ? 'noopener noreferrer' : '';
		const iconFixed = icon !== null ? <FixedImage image={icon} passedClass={passedIconClass} /> : null;
		return link && link.url && link.title ? <Link className={passedClass} to={link.url} target={link.target} rel={rel} activeClassName={activeClass}>{link.title} {iconFixed}</Link> : null;
	}
}

export default WPLink;