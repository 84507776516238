import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';

import * as styleSheet from "./index.module.scss"


const Submenu = ({showIf, changeState, passedClass, children, orientation = "normal"}) => {

	const [ windowWidth, setWindowWidth ] = useState(0);
	const [ left, setLeft ] = useState(0);
	const [ pointerOffset, setPointerOffset ] = useState(0);

	const containerTransitionStyles = {
		entering: { 
			left: `-${left}px`,
			opacity: 0,
			margin: (orientation === "flipped") ? "0" : "24px 0 0 0",
		},
		entered:  { 
			left: `-${left}px`,
			opacity: 1,
			margin: (orientation === "flipped") ? "0 0 10px 0" : "4px 0 0 0",
		},
		exiting:  { 
			left: `-${left}px`,
			opacity: 0,
			margin: (orientation === "flipped") ? "0" : "24px 0 0 0",

		},
		exited:  { 
			left: `-${left}px`,
			opacity: 0,
			margin: (orientation === "flipped") ? "0" : "24px 0 0 0",
		},
 	};

	const pointerStyles = {
		left: `${pointerOffset}px`,
 	};

	useEffect( () => {
		const onWindowResize = () => {
			if(typeof window !== undefined){ 
				setWindowWidth(window.innerWidth);

				if( changeState !== undefined ){
					changeState(false);
				}
			}
		};

		if(typeof window !== undefined){
			setWindowWidth(window.innerWidth)
			window.addEventListener('resize', onWindowResize);
		}

		return () => {
			if(typeof window !== undefined){
				window.removeEventListener('resize', onWindowResize);
			}
		}
	}, [changeState]);

	const onOpen = (node, isAppearing) => {

		const nodeBC = node.getBoundingClientRect();
		
		const parentMiddle = node.offsetParent.offsetWidth / 2;
		let newOffset = 0;
		let newPointerOffset = 0;

		const windowOffset = nodeBC.left;
		const idealOffset = ((nodeBC.width / 2) - parentMiddle);

		const idealOffsetWillBeOffscreenLeft = idealOffset > windowOffset; // Determine if centering the submenu would make the menu appear offscreen on the left

		newOffset = idealOffsetWillBeOffscreenLeft ? (windowOffset - 5) : idealOffset;
		newPointerOffset = idealOffsetWillBeOffscreenLeft ? (idealOffset + parentMiddle) - (idealOffset - windowOffset)  : (idealOffset + parentMiddle);

		const idealOffsetWillBeOffscreenRight = (nodeBC.width + (nodeBC.left - newOffset)) > windowWidth;

		if( idealOffsetWillBeOffscreenRight ){
			const cachedOffset = newOffset;

			newOffset = (newOffset + ((nodeBC.width + (nodeBC.left - newOffset)) - windowWidth)) + 5;
			newPointerOffset = newPointerOffset + (newOffset - cachedOffset);
		}

	
		setLeft(newOffset);
		setPointerOffset(newPointerOffset);

	};

	const onExited = (node) => {
		setLeft(0);
		setPointerOffset(0);
	}
	
	return(
		<Transition in={showIf} timeout={250} mountOnEnter unmountOnExit onEnter={(node,isAppearing)=>{ onOpen(node, isAppearing) }} onExited={(node)=>{ onExited(node) }}>
			{(state) => (
				<div className={passedClass} style={{...containerTransitionStyles[state]}}>
					<div className={orientation === "flipped" ? styleSheet.pointerflipped : styleSheet.pointer } style={{...pointerStyles}}></div>
					{children}
				</div>
			)}
		</Transition>
	);
}

export default Submenu;
