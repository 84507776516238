import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import FluidImage from "../fluidImage";
import FixedImage from "../fixedImage";
import WPLink from "../wpLink";

import * as styleSheet from "./index.module.scss"

const Footer = () => {
	const data = useStaticQuery(graphql`query FooterQuery {
  options: wp {
    socialSection {
      ACFSocial {
        facebookUrl
        instagramUrl
        twitterUrl
      }
    }
    footerSection {
      ACFFooterOptions {
        footerBackgroundImageMobile: footerBackgroundImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 699, layout: CONSTRAINED)
            }
            publicURL
            extension
          }
        }
        footerBackgroundImageTablet: footerBackgroundImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 999, layout: CONSTRAINED)
            }
            publicURL
            extension
          }
        }
        footerBackgroundImageDesktop: footerBackgroundImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            publicURL
            extension
          }
        }
        footerBackgroundImageXL: footerBackgroundImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            publicURL
            extension
          }
        }
        footerCalloutBlurb
        footerCalloutHeading
        footerCalloutLink {
          target
          title
          url
        }
        footerCalloutLogo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 289, layout: CONSTRAINED)
            }
            publicURL
            extension
          }
        }
        footerMenu {
          link {
            target
            title
            url
          }
        }
        footerLocationsPageLink {
          target
          title
          url
        }
        copyrightText
      }
    }
  }
  locations: allWpLocation(sort: {fields: title}) {
    nodes {
      id
      title
      link
      ACFLocation {
        menuTitle
      }
      birdeyeLocation {
				location {
					lat
					lng
					city
					state
				}
			}
    }
  }
  services: allWpService(sort: {fields: title}) {
    nodes {
      title
      link
      id
    }
  }
  facebook: file(name: {eq: "facebook"}) {
    childImageSharp {
      gatsbyImageData(width: 20, layout: FIXED)
    }
  }
  instagram: file(name: {eq: "instagram"}) {
    childImageSharp {
      gatsbyImageData(width: 20, layout: FIXED)
    }
  }
  twitter: file(name: {eq: "twitter"}) {
    childImageSharp {
      gatsbyImageData(width: 24, layout: FIXED)
    }
  }
}
`);

	const {
		options: {
			footerSection: {
				ACFFooterOptions: {
					footerBackgroundImageMobile,
					footerBackgroundImageTablet,
					footerBackgroundImageDesktop,
					footerBackgroundImageXL,
					footerCalloutBlurb,
					footerCalloutHeading,
					footerCalloutLink,
					footerCalloutLogo,
					footerMenu,
					footerLocationsPageLink,
          copyrightText
				}
			},
			socialSection: {
				ACFSocial: {
					facebookUrl,
					instagramUrl,
					twitterUrl,
				}
			}
		},
		locations: {
			nodes: locations
		},
		services: {
			nodes: services
		},
		facebook,
		instagram,
		twitter
	} = data;

	return (
		<footer className={styleSheet.Footer}>
			<div className={styleSheet.inner}>
				{ footerBackgroundImageMobile && <FluidImage passedClass={styleSheet.bgmobile} image={footerBackgroundImageMobile} /> }
				{ footerBackgroundImageTablet && <FluidImage passedClass={styleSheet.bgtablet} image={footerBackgroundImageTablet} /> }
				{ footerBackgroundImageDesktop && <FluidImage passedClass={styleSheet.bgdesktop} image={footerBackgroundImageDesktop} /> }
				{ footerBackgroundImageXL && <FluidImage passedClass={styleSheet.bgxl} image={footerBackgroundImageXL} /> }
				
				<div className={styleSheet.callout} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
					<div className={styleSheet.top}>
						{ footerCalloutLogo && <FluidImage image={footerCalloutLogo} passedClass={styleSheet.logo} /> }
						{ (facebookUrl || instagramUrl || twitterUrl) && (
							<div className={styleSheet.social}>
								{ facebookUrl && <a href={facebookUrl} target="_blank" rel="noopener noreferrer"><span>Facebook</span><FixedImage image={facebook} passedClass={styleSheet.fb} /></a> }
								{ twitterUrl && <a href={twitterUrl} target="_blank" rel="noopener noreferrer"><span>Twitter</span><FixedImage image={twitter} passedClass={styleSheet.twitter} /></a> }
								{ instagramUrl && <a href={instagramUrl} target="_blank" rel="noopener noreferrer"><span>Instagram</span><FixedImage image={instagram} passedClass={styleSheet.twitter} /></a> }
							</div>
						) }
					</div>
					<div className={styleSheet.bottom}>
						{ footerCalloutHeading && <span className={styleSheet.heading} dangerouslySetInnerHTML={{__html: footerCalloutHeading}}></span> }
						{ footerCalloutBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: footerCalloutBlurb}}></div> }
						{ footerCalloutLink && <WPLink link={footerCalloutLink} passedClass={styleSheet.link} /> }
					</div>
				</div>
				<div className={styleSheet.menus}>
					<div className={styleSheet.services}>
						{ services.length > 0 && (
							<ul className={styleSheet.list}>
								<li className={styleSheet.listitemmain}><span className={styleSheet.link}>Services</span></li>
								{ services.map( (service, _index) => {
									return (
										<li key={service.id} className={styleSheet.listitemsecondary}>
											<Link to={service.link} className={styleSheet.link} dangerouslySetInnerHTML={{__html: service.title}} />
										</li>
									);
								})}
							</ul>
						)}
					</div>
					<div className={styleSheet.locations}>
						{ locations.length > 0 && ( 
							<ul className={styleSheet.list}>
								{ footerLocationsPageLink && <li className={styleSheet.listitemmain}><WPLink link={footerLocationsPageLink} passedClass={styleSheet.link} /></li> }
								{ locations.map( (location, _index) => {
									return (
										<li key={location.id} className={styleSheet.listitemsecondary}>
											<Link to={location.link} className={styleSheet.link} dangerouslySetInnerHTML={{__html: location.ACFLocation.menuTitle ? `${location.ACFLocation.menuTitle}` : `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}} />
										</li>
									);
								})}
							</ul>
						)}
					</div>
					<div className={styleSheet.footermenu}>
						{ footerMenu && (
							<nav className={styleSheet.nav}>
								<ul className={styleSheet.list}>
									{ footerMenu.map( (menuitem, index) => {
										return (
											<li key={index} className={styleSheet.listitemmain}>
												<WPLink link={menuitem.link} passedClass={styleSheet.link} />
											</li>
										);
									})}
								</ul>
							</nav>
						)}
					</div>
				</div>
				<div class="be-ix-link-block"></div>
				<div className={styleSheet.copyright}>{new Date().getFullYear()}{copyrightText ?  copyrightText : '&copy; All Rights Reserved'}</div>
			</div>
		</footer>
	);
};

export default Footer;
