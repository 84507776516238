/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby" 
import { SalWrapper } from "react-sal";
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import Header from "../header";
import Footer from "../footer";
import "./styles.css";

const Layout = ({ children, showMobileCTA = true }) => {
	const {
        wp: { seo },
    } = useStaticQuery(graphql`
        query SiteInfoQuery {
            wp {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaRobotsNoindex
                            metaDesc
                        }
                        page {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
						dentist {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
						location {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
						service {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
                    }
                    webmaster {
                        googleVerify
                        yandexVerify
                        msVerify
                        baiduVerify
                    }
                    schema {
                        companyName
                        personName
                        companyOrPerson
                        wordpressSiteName
                        siteUrl
                        siteName
                        inLanguage
                        logo {
                            sourceUrl
                            mediaItemUrl
                            altText
                        }
                    }
                    social {
                        facebook {
                            url
                            defaultImage {
                                sourceUrl
                                mediaItemUrl
                            }
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        mySpace {
                            url
                        }
                        pinterest {
                            url
                            metaTag
                        }
                        twitter {
                            username
                        }
                        wikipedia {
                            url
                        }
                        youTube {
                            url
                        }
                    }
                }
            }
        }
    `);

  	return (
		<SEOContext.Provider value={{ global: seo }}>
			<SalWrapper>
				<Header showMobileCTA={showMobileCTA} />
				<main>{children}</main>
				<Footer />
			</SalWrapper>
		</SEOContext.Provider>
  	)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
