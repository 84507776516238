// extracted by mini-css-extract-plugin
export var NearestDentist = "index-module--NearestDentist--hAWg6";
export var bottom = "index-module--bottom--2gKfU";
export var icon = "index-module--icon--fdu+o";
export var image = "index-module--image--+HF-M";
export var learnmore = "index-module--learnmore--8aDji";
export var left = "index-module--left--8jzj8";
export var link = "index-module--link--Sxdpa";
export var linkhover = "index-module--linkhover--V0Dy+";
export var locationlink = "index-module--locationlink--6DNOP";
export var right = "index-module--right--vc3hR";
export var title = "index-module--title--RLka4";
export var top = "index-module--top--wPP4h";