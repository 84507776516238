import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styleSheet from "./index.module.scss"

const FixedImage = ({ image, passedClass = styleSheet.defaultStyles }) => {
	const hasLocalFile = image.localFile !== undefined;
	const childImageSharp = hasLocalFile ? image.localFile.childImageSharp : image.childImageSharp;
  	return childImageSharp ? <GatsbyImage 
	placeholder="none" 
	image={childImageSharp.gatsbyImageData} 
	style={{
		display: "inline-block"
	}}
	className={passedClass} 
	alt='' /> : null;
}

export default FixedImage;